/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */


// react
import { useRef, useState } from "react";

// next
import dynamic from "next/dynamic";
import Image from "next/image";

// MUI
import MDBox from "/components/MDBox";

// MUI Icons
const PlayArrowRounded = dynamic(() => import('@mui/icons-material/PlayArrowRounded'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });

function ALLDVideo({ row }) {
  const boxRef = useRef();
  const imageRef = useRef();
  const [, setHover] = useState(false);

  return (
    <MDBox
      ref={boxRef}
      sx={{ position: "relative" }}
      onClick={(e) => {
        e.preventDefault();
        const iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('allow', 'autoplay; fullscreen');
        iframe.setAttribute("frameborder", 0)
        iframe.setAttribute('src', 'https://www.youtube.com/embed/' + row.vid + '?autoplay=1&modestbranding=1');
        iframe.setAttribute('style', 'display:block;width:100%;height:100%;position:absolute');
        imageRef.current.setAttribute("style", "display:none");
        boxRef.current.setAttribute("style", "padding-bottom: 56.25%");
        boxRef.current.appendChild(iframe);
      }}
    >
      <MDBox ref={imageRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <MDButton
          variant="contained"
          iconOnly
          size="large"
          color="white"
          aria-label={`play the ${row.title} video`}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-26px",
            marginTop: "-26px",
            zIndex: 11,
            borderRadius: 18,
          }}>
          <PlayArrowRounded sx={{ fontSize: 40 }} />
        </MDButton>
        <Image
          src={row.url}
          alt={row.title}
          width={0}
          height={0}
          sizes="40vw"
          style={{ objectFit: "cover", width: '100%', height: 'auto' }}
        />
      </MDBox>
    </MDBox>
  );
}

export default ALLDVideo;